@import "../mainVariables";
@import "../mixins";

.companies-wrapper{
  //-webkit-user-select:none!important;
  //user-select: none!important;
  position: relative;
  background-color: #F9F9F9;
  overflow: hidden;
  .companies-main-block{
    padding: 120px $mainPadding 80px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left-block{
      width: 48%;
      display: flex;
      flex-direction: column;
      .blocks-title{
        font-size: 64px;
        font-weight: 800;
        line-height: 86px;
        letter-spacing: 0;
        text-align: left;
      }
      .blocks-description{
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0;
        text-align: left;
        color: #7E8198;
        margin-top: 16px;
        p{
          span{
            font-size: 18px!important;
            font-weight: 400!important;
            line-height: 28px!important;
            letter-spacing: 0!important;
            text-align: left!important;
            color: #7E8198!important;
            background-color: transparent!important;
          }
        }
      }
      .blocks-link{
        display: flex;
        align-items: center;
        margin-top: 16px;
        cursor: pointer;
        span{
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0;
          text-align: left;
          color: #0072BC;
        }
        svg{
          margin-left: 8px;
          transition: transform 0.2s;
          transform: rotate(0deg);
          path{
            stroke: #0072BC;
          }
        }
        &:hover{
          svg{
            transform: rotate(0deg) translateX(6px);
          }
        }
      }
    }
    .right-block{
      width: 50%;
      position: relative;
      .blocks-img{
        position: relative;
        padding-top: 88%;
        img{
          position: absolute;
          top: 0;
          right: 11%;
          bottom: 0;
          width: 80%;
          height: 100%;
          border-radius: 10px;
          object-fit: cover;
        }
      }
      .border-img{
        position: initial;
        padding-top: 15.8%;
        z-index: 1003;
        svg{
          position: absolute;
          top: -41%;
          right: -32.3%;
          bottom: 30%;
          width: 172%;
          height: 120%;
        }
      }
    }
  }
  .companies-news-blog{
    padding: 60px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    .news-title{
      width: 80%;
      font-size: 56px;
      font-weight: 600;
      line-height: 74px;
      letter-spacing: 0;
      text-align: center;
      display: flex;
      align-self: center;
      color: #26272E;
    }
    .news-description{
      width: 80%;
      font-size: 18px;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0;
      text-align: center;
      display: flex;
      align-self: center;
      color: #7E8198;
      margin-top: 16px;
    }
    .blog-slider-wrapper{
      background-color: transparent;
      .news{
        .slick-track{
          display: flex;
          .slick-slide{
            margin-right: 20px;
            padding-right: unset;
            padding: 24px;
            background-color: white;
            border-radius: 10px;
          }
        }
      }
    }
  }
  .companies-blogs{
    width: 100%;
    display: flex;
    flex-direction: column;
    .products-title{
      width: 70%;
      padding: 0 $mainPadding;
      font-size: 56px;
      font-weight: 600;
      line-height: 84px;
      letter-spacing: 0;
      text-align: left;
      color: #26272E;
    }
    .products-description{
      width: 60%;
      padding: 0 $mainPadding;
      margin-top: 16px;
      font-size: 18px;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0;
      text-align: left;
      color: #7E8198;
    }
    .blocks{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row-reverse;
      padding-right:$mainPadding;
      margin-top: 80px;
      .img-wrapper{
        width: 50%;
        position: relative;
        .blocks-img{
          position: relative;
          padding-top: 73%;
          img{
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: 90%;
            height: 100%;
            border-radius: 10px;
            object-fit: cover;
          }
        }
        .border-block{
          position: initial;
          padding-top: 15.8%;
          z-index: 1003;
          svg{
            position: absolute;
            top: 0;
            right: 18%;
            bottom: 0;
            width: 90%;
            height: 100%;
          }
        }
      }
      .blocks-text{
        width: 50%;
        display: flex;
        align-items: start;
        flex-direction: column;
        margin-left: 40px;
        position: relative;
        z-index: 1000;
        .blocks-date{
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0;
          text-align: left;
          color: #0072BC;
          margin-bottom: 16px;
        }
        .blocks-title{
          font-size: 32px;
          font-weight: 600;
          line-height: 48px;
          letter-spacing: 0;
          text-align: left;
          color: #26272E;
          margin-bottom: 8px;
        }
        .blocks-description{
          width: 90%;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0;
          text-align: left;
          color: #7E8198;
          p{
            span{
              font-size: 16px!important;
              font-weight: 400!important;
              line-height: 24px!important;
              letter-spacing: 0!important;
              text-align: left!important;
              color: #7E8198!important;
              background: transparent!important;
            }
          }
        }
        .companies-block-link{
          display: flex;
          align-items: center;
          justify-content: start;
          margin-top: 16px;
          cursor: pointer;
          span{
            border-bottom: 1.5px solid #424350;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0;
            text-align: left;
            color: #424350;
            padding-bottom: 5px;
          }
          svg{
            transition: transform 0.2s;
            transform: rotate(0deg);
            margin-left: 6px;
          }
          &:hover{
            span{
              color: #0072BC;
              border-color:#0072BC;
            }
            svg{
              transform: rotate(0deg) translateX(6px);
              path{
                stroke: #0072BC;
              }
            }
          }
        }
      }
      &:nth-child(odd){
        flex-direction: row;
      }
    }
  }
  .components-info-block{
    padding: 64px $mainPadding;
    background-color: white;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-top: 60px;
    .texts-block{
      width: 80%;
      .info-title{
        font-size: 56px;
        font-weight: 600;
        line-height: 84px;
        letter-spacing: 0;
        text-align: center;
        color: #26272E;
      }
      .info-description{
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0;
        text-align: center;
        color: #7E8198;
        margin-top: 16px;
      }
    }
    .info-wrapper{
      width: 90%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 64px;
      .block{
        width:calc(90% / 3);
        display: flex;
        flex-direction: column;
        align-items: center;
        .number{
          font-size: 56px;
          font-weight: 600;
          line-height: 84px;
          letter-spacing: 0;
          text-align: center;
          color: #0072BC;
        }
        .texts{
          font-size: 18px;
          font-weight: 400;
          line-height: 28px;
          letter-spacing: 0;
          text-align: center;
          color: #424350;
        }
      }
    }
  }

  @media screen and (max-width: 1050px){
    .companies-main-block{
      flex-direction: column;
      padding-bottom: 30px;
      .left-block{
        width: 100%;
        margin-top: 40px;
        .blocks-title{
          font-size: 34px;
          font-weight: 800;
          line-height: 44px;
          letter-spacing: 0;
        }
        .blocks-description{
          font-size: 16px;
          font-weight: 400;
          line-height: 28px;
          letter-spacing: 0;
        }
      }
      .right-block{
        width: 100%;
        margin-top: 40px;
      }
    }
    .companies-news-blog{
      padding: 10px $mainPadding;
      .news-title{
        align-self: start;
        font-size: 26px;
        font-weight: 600;
        line-height: 38px;
        letter-spacing: 0;
        text-align: left;
      }
      .news-description{
        align-self: start;
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        letter-spacing: 0;
        text-align: left;
      }
    }
    .companies-blogs{
      .products-title{
        width: 100%;
        font-size: 26px;
        font-weight: 600;
        line-height: 38px;
        letter-spacing: 0;
        text-align: left;
      }
      .products-description{
        width: 100%;
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        letter-spacing: 0;
        text-align: left;
      }
      .blocks{
        flex-direction: column;
        margin-top: 40px;
        .img-wrapper{
          width: 100%;
        }
        .blocks-text{
          width: 100%;
        }
        &:nth-child(odd){
          flex-direction: column;
        }
      }
    }
    .components-info-block{
      .texts-block{
        width: 100%;
        .info-title{
          font-size: 26px;
          font-weight: 600;
          line-height: 38px;
          letter-spacing: 0;
          text-align: left;
        }
        .info-description{
          font-size: 18px;
          font-weight: 400;
          line-height: 27px;
          letter-spacing: 0;
          text-align: left;
        }
      }
      .info-wrapper{
        width: 100%;
        justify-content: center;
        flex-direction: column;
        .block{
          width: 100%;
          justify-content: center;
          margin-top: 16px;
        }
      }
    }
  }
}