@import "../../../assets/styles/mainVariables";
@import "../../../assets/styles/fonts";

.blog-slider-wrapper{
  width: 100%;
 padding: 80px 0 40px $mainPadding;
  background-color: white;
  .news{
    .slick-track{
      .slick-slide{
        padding-right: 30px;
        :hover {
          .news-block{
            .news-block-info{
              .news-block-href, .news-block-link{
                span{
                  color: #0072BC;
                  border-color:#0072BC;
                }
                svg {
                  transform: rotate(0deg) translateX(6px);
                  path{
                    stroke: #0072BC;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .slider-arr{
    margin-top: 60px;
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 80px;
    //justify-content: space-between;
    .prev-btn{
      position: absolute;
      transform: unset;
      transition: unset;
      border: 1px solid #EAECF0;
      box-shadow: unset;
      width: 80px;
      height: 80px;

    }
    .next-btn{
      margin-right: 32px;
      transform: unset;
      transition: unset;
      border: 1px solid #EAECF0;
      box-shadow: unset;
      width: 80px;
      height: 80px;
      position: absolute;
      right: 0;
    }

    @media screen and (max-width: 1000px){
      .prev-btn, .next-btn{
        border: unset;
        box-shadow: unset;
        width: unset;
        height: unset;
        background-color: transparent;
      }
    }
  }

  @media screen and (max-width: 1050px){
    .news{
      .slick-track{
        .slick-slide{
          padding-right: unset;
          :hover {
            .news-block{
              .news-block-info{
                .news-block-href, .news-block-link{
                  span{
                    color: #0072BC;
                    border-color:#0072BC;
                  }
                  svg {
                    transform: rotate(0deg) translateX(6px);
                    path{
                      stroke: #0072BC;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}