@import "../mainVariables";
@import "../mixins";

.right-menu-wrapper {
  position: fixed;
  top:0;
  right: 0;
  z-index: 9901;
  width: 100%;
  height: 100vh;
  overflow: auto;
  transition: all 0.35s cubic-bezier(.39, .575, .565, 1);
  visibility: hidden;
  opacity: 0;
  margin-top: 100px;

  &.expand_on {
    visibility: visible;
    opacity: 1;
  }

  .right-menu-content {
    width: 100%;
    height: calc(100vh - 80px);
    padding: 0 $mainPadding;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 9002;
    background-color: white;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0;
    }


    .mobile-nav{
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      //align-items: baseline;

      .top-block{
        width: 100%;
        height: 70vh;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .link-block{
          width: 44%;
          display: flex;
          flex-direction: column;
          .nav-item {
            width: 70%;
            margin: 14px 0;
            display: flex;
            align-items: center;
            p{
              font-size: 40px;
              font-weight: 500;
              line-height: 60px;
              letter-spacing: 0;
              text-align: left;
              cursor: pointer;
              color: black;
            }
            span{
              display: none;
              margin-left: 32px;
              svg{
                width: 24px;
                height: 24px;
              }
            }

            &:hover {
              p{
                color: #0072BC;
              }
              span{
                display: flex;
                margin-top: 12px;
              }
            }
          }

          .header-services{
            list-style: revert;
            margin-left: 20px;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0;
            text-align: left;
            color: #344054;
            li{
              margin: 14px 0;
              cursor: pointer;
              &:hover {
                color: $brandColor;
              }
            }
          }
        }
        .img-wrapper{
          width: 60%;
          .img-block{
            position: relative;
            padding-top: 73%;
            img{
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      .bottom-block{
        width: 100%;
        height: 30vh;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .language-items{
          width: 24%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .language-dropdown-item{
            width:calc(90% / 3);
            display: flex;
            align-items: center;
            //margin-top: 20px;
            .selected-language{
              //padding: 12px 30px;
              cursor: pointer;
              color: #7E8198;
              font-size: 18px;
              font-weight: 400;
              line-height: 27px;
              letter-spacing: 0;
              text-align: left;
              &:hover{
                color: #0072BC;
              }
            }
            .active-language{
              color: #0072BC;
            }
          }
        }
        .page-wrapper{
          width: 24%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-left: auto;
          //padding-top: 40px;
          .links{
            font-size: 18px;
            font-weight: 400;
            line-height: 27px;
            letter-spacing: 0;
            text-align: left;
            cursor: pointer;
            color: #7E8198;
            &:hover{
              color: #0072BC;
            }
          }
        }
      }
    }

      @media all and (max-width: 1100px) {
        width: 100%;
        .mobile-nav{
          .top-block{
            .link-block{
              width: 100%;
              .nav-item{
                width: 96%;
                p{
                  font-size: 36px;
                }
                span{
                  margin-left: 20px;
                }
              }
            }
            .img-wrapper{
              display: none;
            }
          }
          .bottom-block{
            .language-items{
              width: 100%;
            }
            .page-wrapper{
              display: none;
            }
          }
        }
      }
  }

  .mobile-menu-overlay {
    background: white;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9000;
  }


  & {
    .hide {
      display: none;
    }
  }


}
