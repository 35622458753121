@import "../mainVariables";
@import "../mixins";

.image-wrapper {
  width: 100%;
  height: 100%;
  padding-top: 40px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.2s ease-in-out;

  .imgSlider {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .slick-list {
      .img-wrapper {
        .slider-item {
          position: relative;
          padding-top: 100%;

          img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      .slick-dots {
        position: absolute;
        left: $mainPadding;
        bottom: 50px;
        width: 300px;
        display: flex !important;

        li {
          button {
            &::before {
              font-size: 6px;
              line-height: 20px;
              top: 0;
              left: 0;
              width: 20px;
              height: 4px;
              content: '';
              background: white;
              opacity: 1;
              border-radius: 2px;
            }
          }

          &:hover {
            button::before {
              background: $brandColor;
            }
          }
        }

        .slick-active {
          button::before {
            background: $brandColor;
          }
        }
      }
    }
  }

  .bottom-block {
    padding-top: 20px;

    .contentSlider {
      height: 230px;

      .slick-list {
        overflow: unset;

        .slider-item {
          width: 100% !important;
          padding-top: unset !important;

          .slider-content {
            width: 90%;
            height: 160px;
            background-color: white;
            border-radius: 12px;
            padding: 26px 26px 40px 26px;
            position: relative;
            box-shadow: 0 4px 40px 0 #0015831A;
            z-index: 2000;

            .title {
              font-size: 22px;
              font-weight: 800;
              line-height: 34px;
              letter-spacing: 0;
              text-align: left;
              display: flex;
              align-items: center;
              color: #0072BC;
              cursor: pointer;

              span {
                margin-left: 10px;
                margin-top: 6px;
              }

              svg {
                transition: transform 0.2s;
                transform: rotate(0deg);
                margin-bottom: 2px;
              }

              &:hover {
                svg {
                  transform: rotate(0deg) translateX(6px);
                }
              }
            }

            .description {
              margin-top: 8px;
              font-size: 14px;
              font-weight: 400;
              line-height: 22px;
              letter-spacing: 0;
              text-align: left;
              overflow: hidden;
              color: #7E8198;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }

            .contact-btn {
              width: 196px;
              height: 68px;
              padding-left: 18px;
              background-color: #EC008C;
              border-radius: 12px;
              border-top-left-radius: unset;
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              bottom: -50px;
              right: 30px;

              svg {
                width: 24px;
                height: 24px;
              }

              span {
                font-size: 14px;
                font-weight: 500;
                line-height: 22px;
                letter-spacing: 0;
                text-align: left;
                color: white;
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1100px) {
    margin-top: 30px;
    display: flex;
    //align-items: center;
    //justify-content: center;
    //flex-direction: column;
    .imgSlider {
      padding-top: unset;
      height: 100%!important;
      width: 100%!important;

      .slick-list {
        width: 100%!important;
        //display: flex;
        .slick-track {
          .img-wrapper{
            width: 100%!important;
          }
        }
      }
    }
    .bottom-block {
      margin-left: 10%;
      .contentSlider {
        .slick-slider {
          .slick-list {
            .slick-track{
              width: 100%!important;
              .slider-item {
                .slider-content {
                  margin-left: 16px;
                  margin-top: 30px;

                  .title {
                    font-size: 22px;
                  }

                  .description {
                    font-size: 14px;
                  }

                  .contact-btn {
                    display: none;
                  }
                }
              }
            }
          }
        }
        .contact-btn{}
      }
    }
  }
}
