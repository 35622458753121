@import "../mainVariables";
@import "../mixins";

.blog-wrapper {
  //-webkit-user-select:none!important;
  //user-select: none!important;
  background-color: #F9F9F9;
  //padding: 0 $mainPadding;
  .subscribe-wrapper{
    padding: 100px 100px 60px 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .title{
      font-size: 50px;
      font-weight: 600;
      line-height: 74px;
      letter-spacing: 0;
      text-align: center;
      color: #26272E;
    }
    .description{
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
      letter-spacing: 0;
      text-align: center;
      margin-top: 16px;
      color: #7E8198;
    }
  }

  .last-news-wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    .blog-slider-wrapper{
      background-color: transparent;
    }
    .last-news-title{
      font-size: 28px;
      font-weight: 600;
      line-height: 48px;
      letter-spacing: 0;
      text-align: left;
      display: flex;
      align-self: center;
      margin-right: 100px;
      color: #7E8198;
    }
  }

  .news-wrapper{
    margin-top: 90px;
    border-bottom: 1px solid #EAECF0;
    background-color: white;
    padding: 60px $mainPadding;
    .news{
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      .news-block{
        width: calc(94% / 3);
        margin-top: 40px;
        margin-right: 20px;
        &:hover {
            .news-block-info{
              .news-block-href, .news-block-link{
                span{
                  color: #0072BC;
                  border-color:#0072BC;
                }
                svg {
                  transform: rotate(0deg) translateX(6px);
                  path{
                    stroke: #0072BC;
                  }
                }
              }
            }
        }
      }
    }
    .news-wrapper-title{
      font-size: 30px;
      font-weight: 600;
      line-height: 48px;
      letter-spacing: 0;
      text-align: left;
      color: #7E8198;
    }
  }

  .pagination{
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    padding: 40px $mainPadding 100px $mainPadding;
    background-color: white;
    .prev_btn{
      border: 1px solid #EAECF0;
      box-shadow: unset;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      position: absolute;
      right: 160px;
      display: flex;
      align-items: center;
      justify-content: center;
      //padding-left:$mainPadding;
      svg{
        margin-right: 12px;
      }
    }
    .next_btn{
      border: 1px solid #EAECF0;
      box-shadow: unset;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      position: absolute;
      right: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      //padding-right:$mainPadding;
      svg{
        margin-left: 12px;
      }
    }
    .ant-pagination-item{
      display: flex;
      align-items: center;
      justify-content: center;
      border: unset;
      margin-top: 44px;
      a{
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0;
        text-align: left;
        color: #667085;
      }
    }
    .ant-pagination-item-active{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 44px;
      a{
        font-size: 26px;
        font-weight: 400;
        line-height: 39px;
        letter-spacing: 0;
        text-align: left;
        color: #424350;
      }
    }
  }
  @media screen and (max-width: 1100px){
    .subscribe-wrapper{
      padding:160px $mainPadding 0 ;
      .title{
        font-size: 32px;
        line-height: 40px;
        text-align: left;
      }
      .description{
        font-size: 14px;
        text-align: left;
      }
      .input-wrapper{
        width: 100%;
        flex-direction: column;
        .custom-input-group{
          width: 100%;
        }
        .sent-email{
          width: 100%;
          margin-left: unset;
          margin-top: 16px;
        }
      }
    }
    .last-news-wrapper{
      margin-top: 60px;
      .blog-slider-wrapper{
        padding: 32px $mainPadding 0;
      }
      .last-news-title{
        align-self: unset;
        margin-right: unset;
        margin-left: 20px;
        font-size: 20px;
      }
      .last-news{
        flex-direction: column;
        .first-news-block{
          width: 100%;
          .first-news{
            .last-news-img{
              padding-top: 58.3%;
            }
            .last-news-info{
              .date{
                font-size: 14px;
              }
              .title-wrapper{
                .title{
                  font-size: 20px;
                }
              }
              .description{
                font-size: 16px;
                -webkit-line-clamp: 3;
              }
            }
          }
        }
        .other-news-block{
          margin-top: 32px;
          width: 100%;
          .other-news{
            flex-direction: column;
            align-items: unset;
            .img-wrapper{
              width: 100%;
              .last-news-img{
                padding-top:58.3%;
              }
            }
            .last-news-info{
              width: 100%;
              .date{
                margin-top: 32px;
                font-size: 14px;
              }
              .title-wrapper{
                .title{
                  font-size: 20px;
                }
              }
              .description{
                font-size: 16px;
                -webkit-line-clamp: 3;
              }
            }
          }
        }
      }
    }
    .news-wrapper{
      margin-top: unset;
      .news{
        .news-block{
          width: 100%
        }
      }
      .news-wrapper-title{
        font-size: 20px;
      }
    }
    .pagination{
      justify-content: space-between;
      .ant-pagination-item{
        margin-top: unset;
      }
      .prev_btn, .next_btn{
        border:unset;
        position: unset;
        height: unset;
        width: unset;
        display: flex;
        align-items: center;
        justify-content: center;
        svg{
          margin-right: unset;
        }
        span{
          display: none;
        }
      }
    }
  }
}