@import "../mainVariables";
@import "../mixins";

.about-wrapper{
  //-webkit-user-select:none!important;
  //user-select: none!important;
  position: relative;
  background-color: #F9F9F9;
  .aboutBg{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    overflow: hidden;
  }
  .responsiveBg{
    display: none;
  }
  .about-main-wrapper{
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 120px $mainPadding 80px;
    position: relative;
    z-index: 1000;
    .about-main{
      width: 48%;
      display: flex;
      align-items: start;
      flex-direction: column;
      .about-main-title{
        font-size: 60px;
        font-weight: 800;
        line-height: 96px;
        letter-spacing: 0;
        text-align: left;
        color: #26272E;
        @media screen and (max-width: 1650px){
          font-size: 48px;
        }
        @media screen and (max-width: 1200px){
          font-size: 40px;
        }
      }
      .about-main-description{
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0;
        text-align: left;
        color: #7E8198;
        margin-top: 16px;
      }
    }
    .main-img{
      width: 48%;
      .img-block{
        position: relative;
        padding-top: 87%;
        img{
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .blocks-wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .blocks{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row-reverse;
      padding-right:$mainPadding;
      margin-top: 80px;
      .img-wrapper{
        width: 50%;
        position: relative;
        .blocks-img{
          position: relative;
          padding-top: 73%;
          img{
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: 90%;
            height: 100%;
            object-fit: cover;
          }
        }
        .border-block{
          position: initial;
          padding-top: 15.8%;
          z-index: 1003;
          svg{
            position: absolute;
            top: 0;
            right: 18%;
            bottom: 0;
            width: 90%;
            height: 100%;
          }
        }
      }
      .blocks-text{
        width: 50%;
        display: flex;
        align-items: start;
        //justify-content: start;
        flex-direction: column;
        margin-left: 40px;
        position: relative;
        z-index: 1000;
        .blocks-title{
          font-size: 32px;
          font-weight: 600;
          line-height: 48px;
          letter-spacing: 0;
          text-align: left;
          color: #26272E;
          margin-bottom: 8px;
        }
        .blocks-description{
          width: 90%;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0;
          text-align: left;
          color: #7E8198;
        }
      }
      &:nth-child(odd){
        flex-direction: row;
      }
    }
  }

  .about-slider-wrapper{
    background-color: white;
    width: 100%;
    margin-top: 60px;
    .text-wrapper{
      width: 80%;
      padding: 64px 0 64px 80px;
      .slider-title{
        font-size: 56px;
        font-weight: 600;
        line-height: 74px;
        letter-spacing: 0;
        text-align: left;
      }
      .slider-description{
        font-size: 22px;
        font-weight: 400;
        line-height: 33px;
        letter-spacing: 0;
        text-align: left;
        color: #7E8198;
        margin-top: 16px;
      }
    }
  }

  @media screen and (max-width: 1000px){
    .aboutBg{
      display: none;
    }
    .responsiveBg{
      display: flex;
      position: absolute;
      top: 0;
      right: 0;
    }
    .about-main-wrapper{
      flex-direction: column;
      padding-bottom: unset;
      .about-main{
        width: 100%;
        .about-main-title{
          font-size: 28px;
          line-height: 40px;
          overflow: hidden;
        }
        .about-main-description{
          font-size: 16px;
          line-height: 28px;
          overflow: hidden;
        }
      }
      .main-img{
        width: 100%;
        margin-top: 32px;
      }
    }
    .blocks-wrapper{
      padding: 40px $mainPadding 80px;
      .blocks{
        margin-top: 40px;
        flex-direction: column-reverse;
        .img-wrapper{
          width: 100%;
        }
        .blocks-text{
          width: 100%;
          margin-bottom: 40px;
          margin-left: unset;
          .blocks-description{
            width: 100%;
            font-size: 16px;
          }
        }
        &:nth-child(odd){
          flex-direction: column-reverse;
        }
      }
    }
    .about-slider-wrapper{
      margin-top: unset;
      background-color: unset;
      .text-wrapper{
        width: 100%;
        padding: 64px $mainPadding;
        overflow: hidden;
        .slider-title{
          font-size: 26px;
          font-weight: 600;
          line-height: 38px;
          letter-spacing: 0;
          text-align: left;
        }
        .slider-description{
          font-size: 18px;
          font-weight: 400;
          line-height: 27px;
          letter-spacing: 0;
          text-align: left;
        }
      }
    }
  }
}