@import "../mainVariables";
@import "../mixins";

.contacts-page-wrapper{
  //-webkit-user-select:none!important;
  //user-select: none!important;
  position: relative;
  background-color: #F9F9F9;
  overflow: hidden;

  .contact-main-block{
    padding: 120px $mainPadding ;
    display: flex;
    align-items: center;
    flex-direction: column;
    .texts{
      width: 84%;
      position: relative;
      z-index: 2000;
      .main-title{
        font-size: 56px;
        font-weight: 600;
        line-height: 74px;
        letter-spacing: 0;
        text-align: center;
        color: #26272E;
      }
      .main-description{
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        letter-spacing: 0;
        text-align: center;
        margin-top: 16px;
        color: #7E8198;
      }
    }
    .responsive-img{
      display: none;
    }
    .main-img-block{
      position: relative;
      width: 100%;
      margin-top: 68px;
      .blocks-img{
        position: relative;
        padding-top: 33.1%;
        img{
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          border-radius: 10px;
          object-fit: cover;
        }
      }
      .border-img{
        position: initial;
        padding-top: 15.8%;
        z-index: 1003;
        svg{
          position: absolute;
          top: -29%;
          width: 171%;
          height: 119.6%;
        }
      }
    }
  }
  .information-block{
    background-color: white;
    padding: 60px $mainPadding;
    .header-block{
      width: 60%;
      display: flex;
      align-items: start;
      flex-direction: column;
      .subTitle{
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
        color: #0072BC;
      }
      .title{
        font-size: 32px;
        font-weight: 600;
        line-height: 48px;
        letter-spacing: 0;
        text-align: left;
        color: #26272E;
      }
    }
    .info-wrapper{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 60px;
      .contact-info{
        width: calc(96% / 3);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .info-icon{
          background-color: #EC008C;
          height: 48px;
          width: 60px;
          border-radius: 10px;
          border-bottom-left-radius: unset;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .info-title{
          font-size: 18px;
          font-weight: 600;
          line-height: 27px;
          letter-spacing: 0;
          text-align: center;
          color: #424350;
          margin-top: 10px;
        }
        .info-description{
          font-size: 18px;
          font-weight: 400;
          line-height: 27px;
          letter-spacing: 0;
          text-align: center;
          color: #7E8198;
          margin-top: 10px;
          &:hover{
            color: #0072BC;
          }
        }
      }
    }
  }
  .contacts-block{
    width: 100%;
    padding: 60px $mainPadding;
    .contacts-main-text{
      width: 70%;
      .main-title{
        font-size: 56px;
        font-weight: 600;
        line-height: 74px;
        letter-spacing: 0;
        text-align: left;
        color: #26272E;
      }
      .main-description{
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
        margin-top: 16px;
        color: #7E8198;
      }

    }
    .send-block{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 60px;
      .contacts-main-block{
        width: 48%;
        .contacts-inputs-wrapper{
          width: 80%;
          display: flex;
          align-items: start;
          justify-content: start;
          flex-direction: column;
          .custom-input-group{
            width: 100%;
            margin-top: 16px;
            input{
              border-bottom: unset;
              background-color: white;
              border-radius: 10px;
              padding: 22px;
              height: 68px;
            }
            textarea{
              height: 116px;
              border-bottom: unset;
              background-color: white;
              border-radius: 10px;
              padding: 22px;
            }
          }
        }
        .sent-email{
          width: 80%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #EC008C;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0;
          color: white;
          padding: 22px ;
          border-radius: 10px;
          margin-top: 20px;
          &:hover{
            background-color: #EC008C;
            color: white;
          }
        }
      }
      .contact-img-wrapper{
        width: 50%;
        position: relative;
        .blocks-img{
          position: relative;
          padding-top: 73%;
          img{
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: 90%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px;
          }
        }
        .border-block{
          position: initial;
          padding-top: 15.8%;
          z-index: 1003;
          svg{
            position: absolute;
            top: 0;
            right: 18%;
            bottom: 0;
            width: 90%;
            height: 100%;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1050px){
    .contact-main-block{
      padding: unset;
      .texts{
        padding: 140px $mainPadding 0;
        width: 100%;
        align-items: start;
        .main-title{
          font-size: 34px;
          font-weight: 800;
          line-height: 44px;
          letter-spacing: 0;
          text-align: left;
        }
        .main-description{
          font-size: 16px;
          text-align: left;
        }
      }
      .responsive-img{
        display: flex;
        width: 100%;
        position: relative;
        top: -80px;
        .responsive-block{
          width: 100%;
          img{
            width: 100%;
          }
        }
      }
      .main-img-block{
        display: none;
      }
    }
    .information-block{
      .header-block{
        width: 100%;
        .title{
          font-size: 18px;
          font-weight: 600;
          line-height: 28px;
          letter-spacing: 0;
          text-align: left;
        }
      }
      .info-wrapper{
        margin-top: unset;
        flex-direction: column;
        justify-content: center;
        .contact-info{
          width: 100%;
          margin-top: 40px;
        }
      }
    }
    .contacts-block{
      .contacts-main-text{
        width: 100%;
        .main-title{
          font-size: 26px;
          font-weight: 600;
          line-height: 38px;
          letter-spacing: 0;
          text-align: left;
        }
        .main-description{
          font-size: 15px;
          font-weight: 400;
          line-height: 27px;
          letter-spacing: 0;
          text-align: left;
        }
      }
      .send-block{
        flex-direction: column;
        margin-top: 32px;
        .contacts-main-block{
          width: 100%;
          .contacts-inputs-wrapper{
            width: 100%;
          }
          .sent-email{
            width: 100%;
          }
        }
        .contact-img-wrapper{
          margin-top: 64px;
          width: 100%;
          .blocks-img{
            img{
              width: 94%;
              object-fit: cover;
            }
          }
          .border-block{
            svg{
              right: 22%;
            }
          }
        }
      }
    }
  }
}
