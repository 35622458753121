@import "../../../../assets/styles/mainVariables";
@import "../../../../assets/styles/fonts";


.news{
  width: 100%;
  display: block;
  flex-wrap: wrap;
  .news-block{
    background-color: white;
    padding: 25px 24px;
    border-radius: 12px;
    .news-block-img{
      position: relative;
      aspect-ratio: 350 / 220;
      img{
        width: 100%;
        height: 100%;
        border-radius: 10px;
        object-fit: cover;
      }
    }
    .news-block-info{
      width: 100%;
      margin-top: 12px;
      display: flex;
      flex-direction: column;
      .time-wrapper{
        display: flex;
        align-items: center;
        .news-date{
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0;
          text-align: left;
          color: #0072BC;
          margin-right: 6px;
        }
      }
      .news-title-wrapper{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 12px;
        .news-block-title{
          font-size: 26px;
          font-weight: 600;
          line-height: 40px;
          letter-spacing: 0;
          text-align: left;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          color: #26272E;
        }
      }
      .news-block-description{
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        color: #7E8198;
        margin-top: 12px;
      }
      .news-block-href{
        display: flex;
        align-items: center;
        justify-content: start;
        margin-top: 16px;
        //cursor: pointer;
        z-index: 3000;
        span{
          border-bottom: 1.5px solid #424350;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0;
          text-align: left;
          color: #424350;
          padding-bottom: 5px;
        }
        svg{
          transition: transform 0.2s;
          transform: rotate(0deg);
          margin-left: 6px;
        }
      }
      .news-block-link{
        display: flex;
        align-items: center;
        justify-content: start;
        margin-top: 16px;
        cursor: pointer;
        span{
          border-bottom: 1.5px solid #424350;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0;
          text-align: left;
          color: #424350;
         padding-bottom: 5px;
        }
        svg{
          transition: transform 0.2s;
          transform: rotate(0deg);
          margin-left: 4px;
        }
      }
    }
  }

  @media screen and (max-width: 1000px){
      flex-wrap: unset;
      flex-direction: column;
      .news-block{
        width: 100%;
        .news-block-img{
          aspect-ratio: 343 / 200;
        }
        .news-block-info{
          .time-wrapper{
            .news-date{
              font-size: 14px;
            }
          }
          .news-title-wrapper{
            .news-block-title{
              font-size: 20px;
            }
          }
        }
      }
  }
}