@import "../mainVariables";
@import "../mixins";

.partners-wrapper {
  width: 100%;
  padding: 0 $mainPadding 100px $mainPadding;

  .partners-title {
    font-size: 42px;
    font-family: Medium;
    //font-weight: 600;
    color: black;
    margin: 100px 0;
    text-align: center;
  }


  .partners-list-wrapper {
    position: relative;

    .slick-slider {
      //padding-right: 10px;
      .slick-list {
        .slick-track {
          margin-left: 0;
          padding-top: 5px;
        }
      }
    }
    .slick-slide .slick-active{
      width: 300px;
    }

    .partner-item {
      width: 90%;
      height: 100%;
      position: relative;

      .partner-content {
        padding-bottom: 71%;
        position: relative;
        margin-top: 20px;
        img {
          width: 100%;
          height: 100%;
          margin: auto;
          object-fit: cover;
          position: absolute;
          top: 0;
          left: 0;
          border-radius: 10px;
        }
      }
    }
  }

  @media all and (max-width: 1050px) {
    padding-right: 0;
    .partners-title {
      font-size: 28px;
      margin: 80px 0;
    }
    .next-btn, .prev-btn {
      display: none;
    }
  }
  @media all and (max-width: 700px) {
    .partners-title {
      font-size: 22px;
      margin: 60px 0;
    }
    .partners-list-wrapper {
      .partner-item {
      }
    }
  }
}