
.progress-wrapper {
  display: flex;
  align-items: end;
  flex-direction: column;
  width: 90%;
  height: 80px;


  .index-wrapper {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .start-index {
      color: #92A1AB;
      font-weight: 600;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .active-index {
      color: #424350;
      font-size: 18px;
      padding-bottom: 3px;
    }
  }

  .progress-bar {
    width: 60%;
    position: relative;
    border-radius: 5px;
    height: 2px;
    background: #92A1AB;

    .stroke {
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 2px;
      z-index: 10;
      background: #424350;
      display: block;
      border-radius: 5px;
      will-change: width;

      &.animate {
        //animation-delay: 0.5s;
        //animation-fill-mode: forwards;
        //animation-name: progress;
        animation-duration: 0.5s;
        animation-fill-mode: both;
        animation-name: progress;
      }
    }
  }

  span {
    color: black;

  }

  @media screen and (max-width: 1100px) {
    display: none;
  }

  @media screen and (max-height: 850px) {
    height: 70px;
  }
}


@keyframes progress {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}


