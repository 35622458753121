@import "../fonts";
@import "../mainVariables";

.footer-wrapper {
  //-webkit-user-select:none!important;
  //user-select: none!important;
  background-color: #F9F9F9;
  position: relative;
  z-index: 1000;

  .top-part{
    padding: 40px $mainPadding;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    .footer-pdf-download{
      position: absolute;
      bottom: 10px;
      font-size: 18px;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0;
      color: #7E8198;
      text-decoration: underline;
      cursor: pointer;
    }
    .subscribe-block{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .subscribe-info-block{
        width: 48%;
        .title{
          font-size: 45px;
          font-weight: 600;
          line-height: 57px;
          letter-spacing: 0;
          text-align: left;
          color: #26272E;
        }
        .description{
          font-size: 18px;
          font-weight: 400;
          line-height: 28px;
          letter-spacing: 0;
          text-align: left;
          margin-top: 16px;
          color: #7E8198;
        }

      }
      .invalid{
        border: 1px solid red;
      }
      .input-wrapper{
        width: 48%;
        background-color: white;
        height: 68px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 22px;
        .custom-input-group{
          width: 80%;
          margin-bottom: unset;
          input{
            border-bottom: unset;
          }
        }
        .sent-email{
          background-color: transparent;
          cursor: pointer;
          svg{
            cursor: pointer;
            padding-top: 6px;
            transition: transform 0.2s;
            transform: rotate(0deg);
          }
        }
        &:hover{
          .sent-email{
            svg{
              transform: rotate(0deg) translateX(6px);
            }
          }
        }
      }
    }
    .info-block{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 42px;
      .social-us{
        width: 48%;
        display: flex;
        align-items: center;
        .blocks{
          margin-right: 36px;
          display: flex;
          flex-direction: column;
          span{
            font-size: 18px;
            font-weight: 400;
            line-height: 28px;
            letter-spacing: 0;
            text-align: left;
            color: #424350;
            &:hover{
              color: #0072BC;
            }
          }
          .info{
            font-size: 18px;
            font-weight: 400;
            line-height: 28px;
            letter-spacing: 0;
            text-align: left;
            margin-top: 8px;
            color: #7E8198;
            &:hover{
              color: #0072BC;
            }
          }
        }
      }
      .follow-us{
        display: flex;
        flex-direction: column;
        .follow-us-title{
          font-size: 18px;
          font-weight: 400;
          line-height: 28px;
          letter-spacing: 0;
          text-align: left;
          color: #424350;
        }
        .links{
          display: flex;
          align-items: center;
          span{
            margin-left:32px;
            font-size: 18px;
            font-weight: 400;
            line-height: 28px;
            letter-spacing: 0;
            text-align: left;
            margin-top: 8px;
            color: #7E8198;
            &:hover{
              a{
                color: #0072BC;
              }
            }
            &:first-child{
              margin-left: unset;
            }
          }
        }
      }
    }
  }

  .bottom-part {
    border-top: 1px solid #EAECF0;
    padding: 32px 0;
    margin: 0 $mainPadding;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;

    .copyright {
      color: #667085;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0;
      text-align: left;
      display: flex;
      align-items: center;
    }

    .developed-by {
      display: flex;
      align-items: center;
      color: #667085;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0;
      text-align: right;

      .footer-logo{
        margin-bottom: 0;
        display: flex;
        align-items: center;
        margin-left: 10px;
        width: 100px;
        height: 40px;
      }
    }
  }

  @media screen and (max-width:1100px){
   .top-part{
     flex-direction: column;
     justify-content: flex-start;
     .subscribe-block{
       flex-direction: column;
       .subscribe-info-block{
         width: 100%;
         .title{
           font-size: 26px;
           font-weight: 600;
           line-height: 38px;
           letter-spacing: 0;
           text-align: left;
         }
         .description{
           font-size: 18px;
           font-weight: 400;
           line-height: 28px;
           letter-spacing: 0;
           text-align: left;
         }
       }
       .input-wrapper{
         width: 100%;
         margin-top: 32px;
       }
     }
     .info-block{
       flex-direction: column;
       .social-us{
         width: 100%;
         flex-direction: column;
         align-items: start;
         .blocks{
           display: flex;
           align-items: start;
           flex-direction: column;
           margin-top: 32px;
         }
       }
       .follow-us{
         width: 100%;
         display: flex;
         align-items: start;
         margin-top: 32px;
         .links{
           align-items: start;
           span{
             margin-left: unset;
             margin-right: 32px;
           }
         }
       }
     }
   }

    .bottom-part{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .copyright{
        text-align: center;
      }
    }
  }

}