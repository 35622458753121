@import "../mainVariables";
@import "../mixins";

.blog-details-wrapper {
  //-webkit-user-select:none!important;
  //user-select: none!important;
  padding: 60px 0 0 0;
  background-color: #F9F9F9;
  overflow: hidden;
  .blogBg{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
  }
  .responsiveBg{
    display: none;
  }
  .details-main-block{
    width: 100%;
    display: flex;
    justify-content: space-between;
    //align-items: baseline;
    padding: 60px $mainPadding;
    position: relative;
    z-index: 1000;
    .left-block{
      width: 48%;
      display: flex;
      flex-direction: column;
      align-items: start;
      .block-title{
        font-size: 40px;
        font-weight: 800;
        line-height: 46px;
        letter-spacing: 0;
        text-align: left;
        color: #26272E;
      }
      .time-wrapper{
        display: flex;
        align-items: center;
        .news-date{
          margin-right: 6px;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0;
          text-align: left;
          color: #0072BC;
          margin-top: 16px;
        }
      }
      .block-description{
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        letter-spacing: 0;
        text-align: left;
        color: #7E8198;
        margin-top: 16px;
      }
      .details-info-block{
        width: 100%;
        position: relative;
        z-index: 1000;
        .text{
          width: 100%;
          font-size: 18px;
          font-weight: 400;
          line-height: 28px;
          letter-spacing: 0;
          text-align: left;
          color: #667085;
        }
      }
    }
    .right-block{
      width: 48%;
      .img-block{
        position: relative;
        aspect-ratio: 700 / 440;
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 10px;
        }
      }
    }
  }
  .img-wrapper{
    width: 100%;
    background-color: white;
    padding: 32px var(--mainPadding);
    position: relative;
    z-index: 1000;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: 32px;
    .img{
      width: 100%;
      display: flex;
      flex-direction: column;
      .img-title{
        font-size: 32px;
        font-weight: 600;
        line-height: 48px;
        letter-spacing: 0;
        text-align: left;
        color: #7E8198;
        margin-bottom: 40px;
        display: flex;
        align-self: center;
      }
      .img-block{
        aspect-ratio: 700 / 440;
      }
    }
  }
  .summary-wrapper{
    width: 100%;
    background-color: white;
    padding: 32px var(--mainPadding);
    position: relative;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
    .summary{
      width: 62%;
      .summary-title{
        font-size: 32px;
        font-weight: 600;
        line-height: 48px;
        letter-spacing: 0;
        text-align: left;
        color: #7E8198;
      }
      .summary-description{
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0;
        text-align: left;
        color: #667085;
        margin-top: 32px;
      }
    }
  }

  @media screen and (max-width: 1100px){
    padding-top: 80px;
    .blogBg{
      display: none;
    }
    .responsiveBg{
      display: flex;
      position: absolute;
      top: 0;
      right: 0;
    }
    .details-main-block{
      flex-direction: column;
      .left-block{
        width: 100%;
        .block-title{
          font-size: 34px;
          font-weight: 800;
          line-height: 44px;
          letter-spacing: 0;
          text-align: left;
        }
        .block-description{
          font-size: 16px;
          font-weight: 400;
          line-height: 28px;
          letter-spacing: 0;
          text-align: left;
        }
        .details-info-block{
          flex-direction: column-reverse;
          .text{
            width: 100%;
          }
        }
      }
      .right-block{
        width: 100%;
        margin-top: 32px;
        .img-block{
          aspect-ratio: 343 / 240;
        }
      }
    }
    .img-wrapper{
      display: none;
    }
    .summary-wrapper{
      width: 100%;
      .summary{
        width: 100%;
      }
    }
  }
}