@import "../mainVariables";
@import "../mixins";

.partners-block{
  width: 100%;
  padding: 100px $mainPadding;
  background-color: white;
  margin: 80px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .partners-title{
    font-size: 22px;
    font-weight: 400;
    line-height: 34px;
    letter-spacing: 0;
    text-align: center;
    color: #7E8198;
    margin-bottom: 50px;
  }
  .partners-wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .top-block{
      width: 88%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      .img-wrapper{
        img{
          width: 200px;
          height:48px;
          object-fit: contain;
        }
      }
    }
    .bottom-block{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 30px;
      .img-wrapper{
        img{
          width: 200px;
          height:48px;
          object-fit: contain;
        }
      }
    }
  }

  @media screen and (max-width: 1050px){
    margin: unset;
    padding: 48px $mainPadding 0;
    background-color: transparent;
    .partners-wrapper{
      padding: unset;
      .top-block{
        width: 90%;
        flex-wrap: wrap;
        svg{
          width: calc(96% / 2);
          margin-top: 20px;
        }
      }
      .bottom-block{
        width: 90%;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: unset;
        svg{
          width: calc(96% / 2);
          margin-top: 20px;
        }
      }
    }
  }
}