@import "../mainVariables";
@import "../mixins";

.homepage-wrapper{
  //-webkit-user-select:none!important;
  //user-select: none!important;
  position: relative;
  background-color: #F9F9F9;
  height: 100vh;
  overflow: hidden;
  .background{
    width: 100vw;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    overflow: hidden;
  }
  .background-responsive{
    display: none;
  }
  .blocks-wrapper{
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1000;
    padding-top: 60px;
    .left-wrapper{
      width: 60%;
      padding-left:$mainPadding;
      .home-wrapper{
        width: 94%;
        .home-title{
          color: #26272E;
          font-size: 60px;
          font-weight: 800;
          line-height: 96px;
          letter-spacing: 0;
          text-align: left;
        }
        .home-description{
          color: #424350;
          margin-top: 8px;
          font-size: 22px;
          font-weight: 400;
          line-height: 39px;
          letter-spacing: 0;
          text-align: left;
        }
        .home-link{
          width: 140px;
          margin-top: 32px;
          color: #0072BC;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0;
          text-align: left;
          display: flex;
          align-items: center;
          span{
            margin-left: 8px;
            width: 12px;
            height: 12px;
            margin-bottom: 11px;
            svg{
              width: 100%;
              height: 100%;
              transition: transform 0.2s;
              transform: rotate(0deg);
            }
          }
          &:hover {
            svg {
              transform: rotate(0deg) translateX(6px);
            }
          }
        }
      }
      .moreInfo-wrapper{
        margin-top: 100px;
        width: 94%;
        .icon-wrapper{
          background-color: #EC008C;
          width: 60px;
          height: 48px;
          border-radius: 10px;
          border-bottom-left-radius: unset;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .home-info{
          margin-top: 12px;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: 0;
          text-align: left;
          color: #424350;
        }
      }
    }
    .right-wrapper{
      width: 40%;
      height: 100%;
    }

    .responsive-block{
      display: none;
    }
  }

  @media screen and (max-width: 1100px){
    height: unset;
    padding-bottom: 40px;
    .background{
      display: none;
    }
    .background-responsive{
      display: flex;
      position: absolute;
      top: 0;
      right: 0;
    }
    .blocks-wrapper{
      height: unset;
      flex-direction: column;
      .left-wrapper{
        width: 100%;
        padding-left: unset;
        padding: 100px $mainPadding 0 $mainPadding;
        .home-wrapper{
          width: 100%;
          .home-title{
            font-size: 34px;
          }
          .home-description{
            font-size: 20px;
            line-height: 33px;
          }
          .home-link{
            font-size: 14px;
          }
        }
        .moreInfo-wrapper{
          display: none;
        }
      }
      .right-wrapper{
        width: 100%;
      }
      .responsive-block{
        margin-top: 60px;
        width: 100%;
        padding: 0 $mainPadding;
        display: flex;
        flex-direction: column;
        .responsive-icon-wrapper{
          background-color: #EC008C;
          width: 60px;
          height: 48px;
          border-radius: 10px;
          border-bottom-left-radius: unset;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .responsive-info{
          margin-top: 12px;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: 0;
          text-align: left;
          color: #424350;
        }
      }
    }
  }
}