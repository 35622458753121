@import "../mainVariables";
@import "../mixins";


.openBg{
  background-color: white;
  display: unset!important;
  height: 80px;
  position: fixed!important;
  .close-menu{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;
    transform: unset!important;
  }
}
.header-wrapper {
  width: 100%;
  //-webkit-user-select:none!important;
  //user-select: none!important;
  padding: 0 $mainPadding;
  position: absolute;
  top: 0;
  z-index:3000;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .bg-block{
    width: 52%;
    position: absolute;
    right: 0;
    background-color: transparent;
    border-top-left-radius: 100%;
    overflow: hidden;
    height: 235px;
  }

  a{
    .ask-logo{
      //svg{
      //  width: 150px;
      //  height: 100px;
      //}
    }
  }
  .none{
    display: none;
  }
  .header_burger {
    display: flex;
    transform: translateY(-50%);
    cursor: pointer;
  }
  div{
    position: relative !important;
  }

  @media all and (max-width: 1300px) {
    .nav-menu {

      .nav-item {
        font-size: 16px;
      }
    }
  }

  @media screen and (max-width: 1100px){
    .bg-block{
      display: none!important;
    }
    .header_burger{
      display: flex!important;
    }
    .nav-menu {

      .nav-item {
        display: none;
      }
    }
  }
}