@import "../mainVariables";
@import "../mixins";

.no-result-empty-view {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    width: 400px;
    height: 400px;
    margin-bottom: 20px;
    font-size: 40px;
    font-weight: 500;
    line-height: 60px;
    letter-spacing: 0;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media all and (max-width: 500px) {
    margin: 50px 0;
    img {
      width: 70%;
      height: unset;
    }
    p {
      font-size: 14px;
    }
  }
}
